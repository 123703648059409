<template>
  <div>
    <CRow class="pb-4 mt-2">
      <CCol sm="12" lg="7">
        <div class="form-group form-row d-flex aling-items-center mb-0">
          <label class="col-form-label col-sm-12 col-lg-1 text-right mb-2">{{`${$t('label.date')}`}}</label>
          <div class="input-group col-sm-12 col-lg-4 mb-2">
            <vue-datepicker
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="false"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="FromDay"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <label class="col-form-label col-sm-12 col-lg-auto text-right mb-2">-</label>
          <div class="input-group col-sm-12 col-lg-4 mb-2">
            <vue-datepicker
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="true"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="ToDay"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              value-type="format"
              :show-second="false"
              class="w-100"
            >
              <template #icon-calendar>
                <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </div>
          <div class="d-flex align-items-start justify-content-end col-sm-12 col-lg-auto mb-2">
            <CButton
              color="watch"
              square
              size="sm"
              class="mx-1"
              @click="YardCurrentStatisticsReport"
            >
              <CIcon name="cil-search"/>
            </CButton>
            <CButton
              color="danger"
              square
              size="sm"
              @click="Clear"
            >
              <CIcon name="cil-brush-alt"/>
            </CButton>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="7">
        <div class="block-gray pr-2 d-flex" style="font-size: 9px;">
          <CCol sm="2" class="px-2">
            <div class="d-flex align-items-center justify-content-center" style="height: 100%;">
              <p class="title" style="font-size: 10px !important;">TEUS / {{ $t('label.unit') }}</p>
            </div>
          </CCol>
          <CCol sm="2" class="px-1 d-flex align-items-center justify-content-center">
            <div class="block-white text-center my-2">
              <h4 class="text-blue my-2">{{ TeusJson?.TeusStored ?? 0 }}/{{ TeusJson?.QuantityStored ?? 0 }}</h4>
              <div class="mb-2">
                <b>{{ $t('label.Stored') }}</b>
              </div>
            </div>
          </CCol>
          <CCol sm="2" class="px-1 d-flex align-items-center justify-content-center">
            <div class="block-white text-center my-2">
              <h4 class="text-blue my-2">{{ TeusJson?.TeusReceptioned ?? 0 }}/{{ TeusJson?.QuantityReceptioned ?? 0 }}</h4>
              <div class="mb-2">
                <b>{{ $t('label.Recepcionados') }}</b>
              </div>
            </div>
          </CCol>
          <CCol sm="2" class="px-1 d-flex align-items-center justify-content-center">
            <div class="block-white text-center my-2">
              <h4 class="text-blue my-2">{{ TeusJson?.TeusMovilized ?? 0 }}/{{ TeusJson?.QuantityMovilized ?? 0 }}</h4>
              <div class="mb-2">
                <b>{{ $t('label.mobilized') }}</b>
              </div>
            </div>
          </CCol>
          <CCol sm="2" class="px-1 d-flex align-items-center justify-content-center">
            <div class="block-white text-center my-2">
              <h4 class="text-blue my-2">{{ TeusJson?.TeusDispatched ?? 0 }}/{{ TeusJson?.QuantityDispatched ?? 0 }}</h4>
              <div class="mb-2">
                <b>{{ $t('label.Despachados') }}</b>
              </div>
            </div>
          </CCol>
          <CCol sm="2" class="px-1 d-flex align-items-center justify-content-center">
            <div class="block-white text-center my-2">
              <h4 class="text-blue my-2">{{ TeusJson?.TeusCustomInspection ?? 0 }}/{{ TeusJson?.QuantityCustomInspection ?? 0 }}</h4>
              <div class="mb-2">
                <b>{{ $t('label.Recognized') }}</b>
              </div>
            </div>
          </CCol>
        </div>

        <div class="block-gray text-center mt-2">
          <p class="title">{{ $t('label.customsRegime') }} X {{ $t('label.size') }}</p>
          <div style="height: 268px;" class="d-flex justify-content-center align-items-center">
            <SimpleChartBar
              v-if="CustomOperationJson.some(item => item?.SizeJson.some(Size => Size.Quantity > 0))"
              Width="100%"
              Height="100%"
              :Series="SeriesCustomsRegimeXSize"
              :Categories="LabelCustomsRegimeXSize"
              :Colors="ColorsCustomsRegimeXSize"
            />
            <label v-else class="mb-0" style="color:#e55353">{{ $t('label.noDataAvailable').toUpperCase() }}</label>
          </div>
        </div>

        <div class="block-gray text-center my-2">
          <p class="title">{{ $t('label.newShippingLine') }}</p>
          <div style="height: 224px;" class="d-flex justify-content-center align-items-center">
            <SimpleChartBar
              v-if="ShippingLineJson.some(item => item?.SizeJson.some(Size => Size.Quantity > 0))"
              Width="100%"
              Height="100%"
              :Series="SeriesShippingLine"
              :Categories="LabelShippingLine"
              :Colors="ColorsShippingLine"
            />
            <label v-else class="mb-0" style="color:#e55353">{{ $t('label.noDataAvailable').toUpperCase() }}</label>
          </div>
        </div>
      </CCol>
      <CCol sm="12" lg="5">
        <div class="block-gray text-center">
          <p class="title">{{ $t('label.containerType') }}</p>
          <div style="height: 400px;" class="d-flex justify-content-center align-items-center">
            <div v-if="TpCargoJson.some(item => item?.Quantity > 0)" style="width: 100%;">
              <ContainerTypeBar
                Width="100%"
                Height="180px"
                :Series="SeriesContainerType(true)"
                :Categories="LabelContainerType"
                :Colors="ColorsContainerType"
                :Max="MaxNumber"
              />
              <ContainerTypeDonut
                Width="100%"
                Height="220px"
                :Series="SeriesContainerType(false)"
                :Categories="LabelContainerType"
                :Colors="ColorsContainerType"
              />
            </div>
            <label v-else class="mb-0" style="color:#e55353">{{ $t('label.noDataAvailable').toUpperCase() }}</label>
          </div>
        </div>

        <div class="block-gray text-center my-2">
          <p class="title">{{ $t('label.SizeFt') }}</p>
          <div style="height: 224px;" class="d-flex justify-content-center align-items-center">
            <SimplePie
              v-if="SizeJson.some(item => item?.Quantity > 0)"
              Width="100%"
              Height="100%"
              :Series="SeriesZise"
              :Categories="LabelZise"
              :Colors="ColorsZise"
            />
            <label v-else class="mb-0" style="color:#e55353">{{ $t('label.noDataAvailable').toUpperCase() }}</label>
          </div>
        </div>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import GeneralMixin from '@/_mixins/general';
import { DateFormater } from '@/_helpers/funciones';
import moment from 'moment';
import ContainerTypeBar from './container-type-bar';
import ContainerTypeDonut from './container-type-donut';
import SimpleChartBar from './simple-chart-bar';
import SimplePie from "./simple-pie";
//DATA-------------------------
function data() {
  return {
    TeusJson: {},
    TpCargoJson: [],
    CustomOperationJson: [],
    ShippingLineJson: [],
    SizeJson: [],
    FromDay: '',
    ToDay: '',
    previousDate: '',
    laterDate: '',
    IntervalId: null,
  }
}

//methods
async function getYardCargoReception() {
  await this.$http.get('YardCargoReception-by-YardId', { YardId: this.yardData.YardId })
    .then(response => {
      let List = response.data.data;
      this.$store.state.yardManagement.globalSelectContainer = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function YardCurrentStatisticsReport() {
  this.$store.state.yardManagement.loading = true;
  clearInterval(this.IntervalId);
  let ReportJson = {
    YardId: this.yardData.YardId,
    DateStart: this.FromDay ? DateFormater.formatDateTimeWithoutSlash(this.FromDay) : '',
    DateEnd: this.ToDay ? DateFormater.formatDateTimeWithoutSlash(this.ToDay) : '',
  }
  await this.$http.post('YardCurrentStatisticsReport-by-YardId', ReportJson, { root: 'ReportJson' })
    .then(response => {
      let CurrentStatistics = response.data.data ?? [];
      if(CurrentStatistics && CurrentStatistics.length != 0) {
        this.TeusJson = CurrentStatistics[0]?.TeusJson[0] ?? {};
        this.TpCargoJson = CurrentStatistics[0]?.TpCargoJson ?? [];
        this.CustomOperationJson = CurrentStatistics[0]?.CustomOperationJson ?? [];
        this.ShippingLineJson = CurrentStatistics[0]?.ShippingLineJson ?? [];
        this.SizeJson = CurrentStatistics[0]?.SizeJson ?? [];
      }

    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
      this.interval();
    })
}

async function Clear() {
  this.$store.state.yardManagement.loading = true;
  await this.getDateRange();
  await this.YardCurrentStatisticsReport();
}

function SeriesContainerType(Bar) {
  let data = [];
  if (Bar) {
    data.push({ data: this.TpCargoJson.filter(item => item.Quantity > 0).map(item => item.Quantity) });
  }else{
    data = this.TpCargoJson.filter(item => item.Quantity > 0).map(item => item.Quantity);
  }
  return data;
}

function getDateRange(){
  this.previousDate = DateFormater.formatDateRange(process.env.VUE_APP_START_DATE_IN_PATIO);
  this.laterDate = new Date();
  this.FromDay = DateFormater.formatDateTimeWithSlash(moment(this.laterDate).subtract(1, 'M'));
  this.ToDay =  DateFormater.formatTimeZoneToDateTime(this.laterDate);
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function interval() {
  this.IntervalId = setInterval(() => {
    if (!this.$store.state.yardManagement.loading) {
      this.YardCurrentStatisticsReport();
    }
  }, 600000)
}

async function Resest() {
  this.TeusJson = {};
  this.TpCargoJson = [];
  this.CustomOperationJson = [];
  this.ShippingLineJson = [];
  this.SizeJson = [];
  this.FromDay = '';
  this.ToDay = '';
  this.previousDate = '';
  this.laterDate = '';
}

//computed
function LabelContainerType () {
  return this.TpCargoJson.filter(item => item.Quantity > 0).map(item => item.TpCargoCode);
}

function ColorsContainerType() {
  return this.TpCargoJson.filter(item => item.Quantity > 0).map(item => item.Color);
}

function MaxNumber() {
  let Cant = 0;
  this.TpCargoJson.forEach(item => {
    if (item.Quantity > Cant) {
      Cant = item.Quantity;
    }
  })
  Cant = Cant+(Cant*0.3);
  return Cant;
}

function SeriesCustomsRegimeXSize() {
  let Data = [
    {
      name: '20’',
      data: this.CustomOperationJson.map(item => {
        return item?.SizeJson.find(Size => Size.Size == 20)?.Quantity ?? 0;
      }),
    },
    {
      name: '40’',
      data: this.CustomOperationJson.map(item => {
        return item?.SizeJson.find(Size => Size.Size == 40)?.Quantity ?? 0;
      }),
    },
    {
      name: '45’',
      data: this.CustomOperationJson.map(item => {
        return item?.SizeJson.find(Size => Size.Size == 45)?.Quantity ?? 0;
      }),
    }
  ];
  return Data;
}

function LabelCustomsRegimeXSize() {
  let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let Label = this.CustomOperationJson.map(item => {
    return item['CustomOperationDs'+_lang] ?? '';
  })
  return Label.length != 0 ? Label : [''];
}

function ColorsCustomsRegimeXSize() {
  let Colors = [];
  this.CustomOperationJson[0]?.SizeJson?.forEach(item => {
    if(item.Size == 20){
      Colors[0] = item?.SizeColor ?? '';
    }else if(item.Size == 40){
      Colors[1] = item?.SizeColor ?? '';
    }else {
      Colors[2] = item?.SizeColor ?? '';
    }
  })
  return Colors;
}

function SeriesShippingLine() {
  let Data = [
    {
      name: '20’',
      data: this.ShippingLineJson.map(item => {
        return item?.SizeJson.find(Size => Size.Size == 20)?.Quantity ?? 0;
      }),
    },
    {
      name: '40’',
      data: this.ShippingLineJson.map(item => {
        return item?.SizeJson.find(Size => Size.Size == 40)?.Quantity ?? 0;
      }),
    },
    {
      name: '45’',
      data: this.ShippingLineJson.map(item => {
        return item?.SizeJson.find(Size => Size.Size == 45)?.Quantity ?? 0;
      }),
    }
  ];
  return Data;
}

function LabelShippingLine () {
  let Label = this.ShippingLineJson.map(item => item.ShippingLineCode);
  return Label.length != 0 ? Label : [''];
}

function ColorsShippingLine () {
  let Colors = [];
  this.ShippingLineJson[0]?.SizeJson?.forEach(item => {
    if(item.Size == 20){
      Colors[0] = item?.SizeColor ?? '';
    }else if(item.Size == 40){
      Colors[1] = item?.SizeColor ?? '';
    }else {
      Colors[2] = item?.SizeColor ?? '';
    }
  })
  return Colors;
}

function SeriesZise() {
  return this.SizeJson.some(item => item.Quantity > 0) ? this.SizeJson.map(item => item.Quantity) : [];
}

function LabelZise() {
  return this.SizeJson.map(item => item.Size);
}

function ColorsZise() {
  return this.SizeJson.map(item => item.SizeColor);
}

export default {
  name: 'yard-situation-index',
  components: {
    ContainerTypeBar,
    ContainerTypeDonut,
    SimpleChartBar,
    SimplePie,
  },
  data,
  mixins: [GeneralMixin],
  methods: {
    YardCurrentStatisticsReport,
    getYardCargoReception,
    Clear,
    SeriesContainerType,
    getDateRange,
    validateDateRange,
    interval,
    Resest,
  },
  computed: {
    LabelContainerType,
    ColorsContainerType,
    MaxNumber,
    SeriesCustomsRegimeXSize,
    LabelCustomsRegimeXSize,
    ColorsCustomsRegimeXSize,
    SeriesShippingLine,
    LabelShippingLine,
    ColorsShippingLine,
    SeriesZise,
    LabelZise,
    ColorsZise,
    ...mapState({
      yardData: state => state.yardManagement.yardData,
      currentTab: state => state.yardManagement.tabIndex,
      currentPrincipalTab: state => state.yardManagement.yardCollapse,
      CompanyBranchId: state => state.auth.branch.CompanyBranchId,
      YardSelected: state => state.yardManagement.YardSelected,
      yardList: state => state.yardManagement.yardList,
      isSelectYard: state => state.yardManagement.isSelectYard,
    })
  },
  watch: {
    currentTab: async function(newVal) {
      if(newVal == 0 && this.currentPrincipalTab == 1 && !this.isSelectYard) {
        this.$store.state.yardManagement.loading = true;
        await this.Resest();
        await this.getDateRange();
        await this.YardCurrentStatisticsReport();
      }else{
        clearInterval(this.IntervalId);
      }
    },
    currentPrincipalTab: async function(newVal, oldVal) {
      if(newVal == 1 && oldVal == 0) {
        this.$store.state.yardManagement.loading = true;
        await this.Resest();
        await this.getDateRange();
        await this.getYardCargoReception();
        await this.YardCurrentStatisticsReport()
        this.$store.state.yardManagement.isSelectYard = false;
      }else{
        clearInterval(this.IntervalId);
      }
    },
    YardSelected: async function (NewVal, oldVal) {
      let YardDataNew = this.yardList.find(item => item.YardId == NewVal);
      let YardDataOld = this.yardList.find(item => item.YardId == oldVal);
      if (YardDataNew && YardDataOld) {
        this.$store.state.yardManagement.loading = true;
        await this.Resest();
        await this.getDateRange();
        await this.getYardCargoReception();
        await this.YardCurrentStatisticsReport();
        this.$store.state.yardManagement.isSelectYard = false;
      }else{
        clearInterval(this.IntervalId);
      }
    }
  }
};
</script>
<style lang="scss">
/* apexcharts -- quitar la opcion de descargar del componente */
.apexcharts-toolbar{
  display: none !important;
}
</style>
<style scoped>
.title{
  font-weight: 700;
  color: #60656e;
  margin-bottom: 0 !important;
}
.block-gray{
  width: 100%;
  background: rgb(198, 198, 201);
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
  border-radius: 5px;
}
.block-white{
  width: 100%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(0, 112, 192);
}
.block-white .text-blue{
  color: rgb(0, 112, 192);
  font-weight: 400 !important;
}
</style>