<template>
  <div class="yard-body" v-if="showLayout">
    <loading-overlay :active="loading" :is-full-page="true" loader="bars" />
    <preferences-modal
      :modal.sync="modalPref"
      :preferencias="preferences"
      :selecteds="currentPreferences"
      :yardId="currentYardId"
      @submit="handleSelecteds"
    />
    <legend-modal
      :viewType="viewType"
      :modal.sync="legendModal"
      :preferencias="currentPreferences"
      :levels="[]"
      :layoutZone="layoutZone"
      :isCoverWareHouse="true"
    />
    <yard-slot-modal 
      :modal.sync="modal" 
      :container="containerData" 
      :ContainerList="ContainerList" 
      :BlNotPositionJson="BlNotPositionJson"
      :ShowList="ShowList" 
      :NotPositioned="NotPositioned"
      :FgInvalidPosition="FgInvalidPosition"
      :isCoverWareHouse="true"
      :ShowContainerListOnly="ShowContainerListOnly"
    />
    <ModalBlSearch
      :modal.sync="ModalBlSearch"
      :BlJson="BlJson"
      @close="ModalBlSearch = false"
    />
    <div class="wrapper d-flex align-items-stretch">
      <nav id="detailSidebar">
        <div class="pb-4 pt-1 sidebar-inner-space">
          <ul class="list-unstyled components mb-5 mx-3">
            <li class="mb-1 mt-4">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                @click.stop="legendModal = true"
              >
                <CIcon name="cil-eye" />
                <span class="ml-1">{{$t('label.legend')}}</span>
              </CButton>
            </li>
            <li class="mb-1">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                :disabled="loading"
                @click.stop="notPositionedContainers()"
              >
                <span class="ml-1">{{$t('label.notPositioned')}}</span>
              </CButton>
            </li>
            <li class="mb-1" v-if="this.NotValidPosJson.length!=0">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                :disabled="loading"
                @click.stop="InvalidPosition()"
              >
                <span class="animation-item text-danger" style="display: inline-block;">
                  <CIcon name='Alerts' style="width: 1.2rem; height: 1.2rem; font-size: 1.5rem;"/>
                </span>
                <span class="ml-1">{{$t('label.invalidPosition')}}</span>
              </CButton>
            </li>
            <li class="mb-1">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                :disabled="loading"
                @click.stop="refreshData"
              >
                <CIcon name="cil-reload"/>
                <span class="ml-1">{{$t('label.refresh')}}</span>
              </CButton>
            </li>

            <li class="mb-1">
              <CButton
                block
                size="sm"
                color="secondary"
                class="d-flex align-items-center justify-content-center"
                :disabled="loading"
                @click.stop="printPdf"
              >
                <CIcon name="cil-print"/>
                <span class="ml-1">{{$t('label.print')}}</span>
              </CButton>
            </li>
          </ul>
        </div>
      </nav>

      <!-- Page Content  -->
      <div id="content">
        <!-- Navbar -->
        <CNavbar
          expandable="lg"
          light class="menu-view-cargo no-margin"
          color="secondary"
        >
           <div class="sidebar-controls">
            <CButton
              color="secondary"
              shape="square"
              class="mr-2"
              size="sm"
              @click.stop="toggleSidebar"
            >
              <CIcon name="cil-menu" />
              <span class="sr-only">Toggle Menu</span>
            </CButton>

            <CButton
              color="primary"
              shape="square"
              size="sm"
              :disabled="isMinimumZoom"
              @click.stop="minusZoom"
            >
              <CIcon name="cil-zoom-out" />
            </CButton>
            <a
              href="#"
              class="zoom-number"
              @click.prevent="resetZoom"
            >
              {{ `${zoom}%` }}
            </a>
            <CButton
              color="primary"
              shape="square"
              size="sm"
              @click.stop="plusZoom"
            >
              <CIcon name="cil-zoom-in" />
            </CButton>
          </div>
          <CToggler in-navbar @click="collapsed = !collapsed" />
          <CCollapse :show="collapsed" navbar>
            <CNavbarNav>
              <div class="mx-2 selects-container">
                <span>{{ `${$t('label.bl')}` }}</span>
                <CInput
                  size="sm"
                  class="no-margin-select ml-2 mr-1"
                  v-model="BlSearch"
                  @input="$store.state.yardManagement.WarehouseBlSearch = ''"
                >
                </CInput>
                <CButton
                  color="primary"
                  shape="square"
                  size="sm"
                  @click="setBlSearch"
                >
                  <CIcon name="cil-search" />
                </CButton>
              </div>
              <div class="mx-2 selects-container">
                <span>{{ `${$t('label.ContainerEquipment')}:` }}</span>
                <CInput
                  size="sm"
                  class="no-margin-select ml-2 mr-1"
                  v-model="ContainerSearch"
                  @input="$store.state.yardsetting.search = ''"
                >
                </CInput>
                <CButton
                  color="primary"
                  shape="square"
                  size="sm"
                  @click="setContainerSearch"
                >
                  <CIcon name="cil-search" />
                </CButton>
              </div>
              <div class="position-relative ml-2 selects-container">
                <span>{{$t('label.view')}}</span>
                <CSelect
                  size="sm"
                  :placeholder="$t('label.select')"
                  class="no-margin-select ml-2 mr-1"
                  :options="viewsOptions"
                  :value.sync="view"
                  :disabled="isEmpty(views)"
                />
                <CButton
                  color="primary"
                  shape="square"
                  size="sm"
                  @click.stop="viewBottom()"
                  :disabled="isEmpty(preferences) || viewType === ''"
                >
                  <CIcon name="cil-filter"/>
                </CButton>
              </div>
            </CNavbarNav>
          </CCollapse>
        </CNavbar>
        <!-- Contenido Interno -->
        <div class="container-fluid">
          <CRow class="mt-5 mb-3">
            <CCol sm="12">
              <div 
                class="zoom-container"
                :class="{ 
                  'grab-bing': !isOgZoom ? true:null,
                  'zoom-center': isLessThanOgZoom ? true:null,
                }"
              >
                <div
                  class="bay-view-container"
                  :style="{ width: `${zoom}%` }"
                >
                  <CoverWarehouse
                    v-if="!loading && currentTab == 1 && currentPrincipalTab == 1"
                    :layout="layoutZone"
                    :viewType="viewType"
                    :preferences="currentPreferences"
                    tooltip
                    :zoom="zoom"
                    :AuxiliarBlock = "AuxiliarBlockJson"
                    :RecognitionZone = "RecognitionZoneJson"
                    @positionSubmit = "refreshData"
                  />
                </div>
              </div>
            </CCol>
          </CRow>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import GeneralMixin from '@/_mixins/general';
  import Zoom from '@/_mixins/ZoomMixin';
  import YardGridMixin from '@/_mixins/yard-grid';
  import YardManagement from '@/_mixins/yardManagement';
  import CoverWarehouse from '@/pages/yard-setting/yards/cover-warehouse';
  import PreferencesModal from './preferences-modal';
  import LegendModal from './legend-modal';
  import YardSlotModal from "@/pages/yard-setting/yards/yard-slot-modal";
  import ModalBlSearch from './modal-blSearch';
  import { dragscroll } from 'vue-dragscroll';
  import { DateFormater, NumberFormater, removeTags } from '@/_helpers/funciones';

  function created() {
    
  }
  //DATA-------------------------
  function data() {
    return {
      VerifiedLevels: [],
      showLayout: true,
      collapsed: false,
      //Filter
      multiple: false,
      normal: false,
      //Usadas para los filter de preferencias.
      filter: [],
      isLoadingFiltros: false,
      isLoadingPuertos: false,
      loading: false,
      //Situacion
      level: 0,
      situation: null,
      layoutZone: [],
      viewType: 'level',
      layoutCurrent: [],
      BlSearch: '',
      ContainerSearch: '',
      BlJson: [],
      //Preferencias
      views: [],
      view: [],
      preferences: [],
      selectedOptions: [],
      modalPref: false,
      //Leyenda
      legendModal: false,
      //search
      ModalBlSearch: false,
    }
  }

  function toggleSidebar() {
    document.querySelector("#detailSidebar").classList.toggle('active');
  }

  function toggleMultiple() {
    this.multiple = !this.multiple;
  }

  function toggleNormales() {
    this.normal = !this.normal;
  }

  function toggleMatching() {
    this.matchingModal = true;
  }

  function getCurrentSituation() {
    this.$store.state.yardManagement.loading = true;
    this.$http
      .get("YardCurrentSituation-by-YardId", {
        YardId: this.currentYardId,
      })
      .then((response) => {
        this.situation = {...response.data.data[0]};
        
        this.layoutCurrent = [
          ...this.situation.CurrentJson[0].YardConfigDetailJson[0].AreaJson,
          ...this.situation.CurrentJson[0].YardConfigDetailJson[0].BlockJson,
        ];
        let total = this.situation.StructureJson[0].YardConfigDetailJson[0].HeightJson.length-1;
        this.level = 0;

        this.layoutZone = [
          ...this.situation.StructureJson[0].YardConfigDetailJson[0].AreaJson.filter(item =>{
            if(process.env.VUE_APP_TP_YARD_AREA_AUXILIAR_AREA == item.TpYardAreaId?.toUpperCase()){
              return item.CargoJson&&item.CargoJson.length!=0 ? true : false;
            }else{
              return true
            }
          }),
        ];
        this.layoutZone = this.layoutZone.map((item) =>
          Object.assign({}, item, {
            resizable: true,
            elementType: item.YardBlockId ? 'block' : 'area',
            i: item.YardBlockId ? item.YardBlockId : item.YardAreaId,
          })
        );
        this.zoom = 100;
        this.CargoNoPosition = this.situation.CargoNoPositionJson;
        this.NotValidPosJson = this.situation?.CurrentJson[0]?.YardConfigDetailJson[0]?.NotValidPosJson ?? [];
        this.BlNotPositionJson = this.situation?.BlNoPositionJson ?? [];
        this.AuxiliarBlockJson =  this.situation.AuxiliarBlockJson;
        this.RecognitionZoneJson = this.situation.RecognitionZoneJson;
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      }).then(() => {
        this.$store.state.yardManagement.loading = false;
      });
  }
  
  function setBlSearch() {
    this.$store.state.yardManagement.loading = true;
    setTimeout(() => {
      let BlCurrentList = [];
      this.situation?.StructureJson?.[0]?.YardConfigDetailJson?.[0]?.AreaJson.forEach(e => 
        e?.BlCargoJson?.forEach(el => {
          if (el?.BlNro?.toUpperCase()==this.BlSearch.toUpperCase()) {
            BlCurrentList.push({ ...el, YardAreaName: e.YardAreaName ?? '' });
          }
        })
      )

      let BlNotPositionedList = this.BlNotPositionJson?.filter(e => 
        e?.BlNro?.toUpperCase()==this.BlSearch.toUpperCase()
      )

      if(BlCurrentList?.length>0 || BlNotPositionedList?.length>0){
        this.$store.state.yardManagement.WarehouseBlSearch = this.BlSearch.toUpperCase();
        this.BlJson = [...BlCurrentList, ...BlNotPositionedList];
        this.$store.state.yardManagement.loading = false;
        this.ModalBlSearch = true;
      } else {
        this.$store.state.yardManagement.loading = false;
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: this.$t('label.noResultsFound'),
          type: "error",
        });
      }
    }, 10);
  }

  function setContainerSearch() {
    this.$store.state.yardManagement.loading = true;
    setTimeout(()=> {
      let AreaFound = this.situation?.CurrentJson[0]?.YardConfigDetailJson[0]?.AreaJson.some(AreaJson => 
        AreaJson?.CargoJson.some(CargoJson => CargoJson?.ContainerCode?.toUpperCase()==this.ContainerSearch.toUpperCase())
      )

      let ContainerNotPositioned = this.situation?.CargoNoPositionJson?.filter(container => 
        container?.ContainerCode?.toUpperCase()===this.ContainerSearch.toUpperCase()
      )

      if(AreaFound||ContainerNotPositioned?.length>0){
        this.$store.state.yardsetting.search = this.ContainerSearch.toUpperCase();
        if(ContainerNotPositioned?.length>0){
          this.$notify({
            group: "container",
            title: "¡Error!",
            text: this.$t('label.containerNotPositioned'),
            type: "error",
          });
          this.ShowContainerListOnly = true;
          this.notPositionedSearch(ContainerNotPositioned);
          this.$store.state.yardManagement.loading = false;
        }
      } else {
        this.$store.state.yardManagement.loading = false;
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: this.$t('label.noContainerFound'),
          type: "error",
        });
      }
    }, 10)
  }

  async function getViews() {
    await this.$http
      .ejecutar("GET", "YardManagementView-list", {
        YardId: this.currentYardId,
      })
      .then((response) => {
        this.views = response.data.data;
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      });
  }

  function getPreferencias() {
    this.$store.state.yardManagement.loading = true;
    this.$http
      .ejecutar("GET", "YardManagementPreference-list", {
        YardViewId: this.view,
      })
      .then((response) => {
        this.preferences = response.data.data;
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      }).finally(() => {
        this.$store.state.yardManagement.loading = false;
      });
  }

  function handleSelecteds(options) {
    this.selectedOptions = [...options];
  }

  function isEmpty(arr) {
    return arr.length === 0;
  }

  function plusZoom() {
    if (this.zoom < 300) {
      this.zoom += 10;
    }
  }

  async function refreshData() {
    this.$store.state.yardManagement.loading = true;
    await this.getViews();
    this.getCurrentSituation();
  }

  function addRemoveLevel(event, item) {
    if(event){
      //ADD
      this.VerifiedLevels.push(item);
    }
    else{
      //DELETE
      this.VerifiedLevels = this.VerifiedLevels.filter((e) =>e.value != item.value);
    }
  }

  async function printPdf() {
    if (this.situation?.StructureJson?.[0]?.YardConfigDetailJson?.[0]?.AreaJson?.some(e => e?.BlCargoJson?.length > 0)) {
      this.$store.state.yardManagement.loading = true;
      let filters = [];
      let booksNames = [];
      let excelHeader = [];
      let excelBooks = [];
      this.situation?.StructureJson?.[0]?.YardConfigDetailJson?.[0]?.AreaJson.map(e => {
        if (e?.BlCargoJson?.length > 0) {
          booksNames.push(e.YardAreaName);
          excelHeader.push(this.excelHeader());
          filters.push([
            `${this.$t('label.yard')}: ${this.yardData.YardName}`,
            this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(new Date()),
            this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : ''),
          ]);
          excelBooks.push(e.BlCargoJson.map((el, index) => { 
            return {
              ...el,
              Nro: index+1,
              YardAreaName: e.YardAreaName ?? '',
              MasterBlNro: el.MasterBlNro ?? '',
              Arrival: el.Arrival ? DateFormater.formatDateTimeWithSlash(el.Arrival) : 'N/A',
              DocumentCode: el.DocumentCode ?? '',
              CustomBrokerName: el.CustomBrokerName ?? '',
              DescriptionOfGoods: el.DescriptionOfGoods ? removeTags(el.DescriptionOfGoods) : '',
              FirstEventDate: el.FirstEventDate ? DateFormater.formatDateTimeWithSlash(el.FirstEventDate) : 'N/A',
              LastEventDate: el.LastEventDate ? DateFormater.formatDateTimeWithSlash(el.LastEventDate) : 'N/A',
            }
          }))
        }
      });
      
      await this.excel(excelHeader, excelBooks, this.$t('label.currentSituationYard'), this.BranchName, [`${this.$t('label.currentSituationYard')}`], filters, booksNames, false);
      this.$store.state.yardManagement.loading = false;
    }
  }

  function ClickDropdown() {
    let ClickDropdown = document.getElementById("Dropdown-prinf");
    ClickDropdown.classList.toggle("show");
    ClickDropdown.lastChild.classList.toggle("show");
    console.log('hhh');
  }

  function excelHeader() {
    let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    return [   
      { text: '#', value: "Nro", aux: "", json: "", isNotFormater: true },
      { text: this.$t('label.bl'), value: "BlNro", aux: "", json: "" },
      { text: this.$t('label.blType'), value: "TpBlName", aux: "", json: "" },
      { text: this.$t('label.Master'), value: "MasterBlNro", aux: "", json: "" },
      { text: this.$t('label.vessel'), value: "VesselName", aux: "", json: "" },
      { text: this.$t('label.voyage'), value: "Voyage", aux: "", json: "" },
      { text: `${'ATA'} / ${'ETA'}`, value: "Arrival", aux: "", json: "" },
      { text: this.$t('label.portActivity'), value: `PortActivityName${_lang}`, aux: "", json: "" },
      { text: this.$t('label.CertificateEntry'), value: "DocumentCode", aux: "", json: "" },
      { text: this.$t('label.client'), value: "ClientName", aux: "", json: "" },
      { text: this.$t('label.customBroker'), value: "CustomBrokerName", aux: "", json: "" },
      { text: this.$t('label.DescriptionOfGoods'), value: "DescriptionOfGoods", aux: "", json: "" },
      { text: this.$t('label.FirstEvent'), value: `FirstEventName${_lang}`, aux: "", json: "" },
      { text: this.$t('label.FirstEventDate'), value: "FirstEventDate", aux: "", json: "" },
      { text: this.$t('label.LastEvent'), value: `LastEventName${_lang}`, aux: "", json: "" },
      { text: this.$t('label.LastEventDate'), value: "LastEventDate", aux: "", json: "" },
      { text: this.$t('label.module'), value: `YardAreaName`, aux: "", json: "" },
      { text: this.$t('label.quantity'), value: "Quantity", aux: "", json: "" },
      { text: `${this.$t('label.weight')} (KGM)`, value: "Weigth", aux: "", json: "", json2: "" },
      { text: `${this.$t('label.volume')} (M³)`, value: "Volumen", aux: "", json: "", json2: "" },
      { text: this.$t('label.blStatus'), value: `YardCargoBlStatus${_lang}`, aux: "", json: "" },
      { text: this.$t('label.status'), value: `BlStatus${_lang}`, aux: "", json: "" },
    ]
  }

  //Computeds
  function viewBottom() {
    if(!this.isEmpty(this.preferences) && this.viewType != '')
      this.modalPref = true
  }

  function currentYardId() {
    return this.yardData ? this.yardData.YardId : '';
  }

  function layoutByViewType() {
    return this.viewType == 'level' ? this.layoutZone : this.layoutCurrent;
  }

  function viewsOptions() {
    if(this.views.length === 0){
      return [{
        value: '', 
        label: this.$t('label.select'),
      }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]

      let _lang = this.$i18n.locale;

      this.views.map(function(e){
          chart.push({
            label: _lang == 'es' ? e.YardViewNameEs : e.YardViewNameEn,
            value: e.YardViewId,
          })     
      })
      return chart;
    }
  }

  function currentPreferences() {
    return this.selectedOptions;
  }

  export default{
      name: 'coverWarehouse-index',
      data,
      mixins: [GeneralMixin, Zoom, YardGridMixin, YardManagement],
      components: {
        CoverWarehouse,
        PreferencesModal,
        LegendModal,
        YardSlotModal,
        ModalBlSearch,
      },
      props: {
        modulo: {
          type: String,
          default: 'stowage'
        }
      },
      directives: {
        dragscroll
      },
      created,
      methods: {
        toggleSidebar,
        toggleMultiple,
        toggleNormales,
        toggleMatching,
        getCurrentSituation,
        setBlSearch,
        setContainerSearch,
        getViews,
        getPreferencias,
        isEmpty,
        handleSelecteds,
        plusZoom,
        refreshData,
        viewBottom,
        printPdf,
        addRemoveLevel,
        ClickDropdown,
        excelHeader,
      },
      computed: {
          currentYardId,
          layoutByViewType,
          viewsOptions,
          currentPreferences,
          ...mapState({
              yardData: state => state.yardManagement.yardData,
              currentTab: state => state.yardManagement.tabIndex,
              currentPrincipalTab: state => state.yardManagement.yardCollapse,
              BranchName: state => state.auth.branch.BranchName,
              user: state => state.auth.user,
          })
      },
      watch:{
        currentTab: async function(newVal) {
          if(newVal == 1 && this.currentPrincipalTab == 1) {
            this.$store.state.yardManagement.loading = true;
            setTimeout(async() => {
              this.DropdownPdf = false;
              this.NotValidPosJson = [];
              this.layoutZone = [];
              this.layoutCurrent = [];
              this.view = '';
              this.BlSearch = '';
              this.ContainerSearch = '';
              this.zoom = 99;
              this.BlJson = [];
              this.$store.state.yardManagement.WarehouseBlSearch = '';
              this.$store.state.yardsetting.search = '';
              this.preferences = [];
              this.selectedOptions = [];
              await this.getViews();
              this.getCurrentSituation();
            }, 400);
          } else {
            this.VerifiedLevels = [];
            this.zoom = 99;
          }
        },
        level: function(newVal, oldVal){

          if(newVal != oldVal){
            this.layoutZone = [
              ...this.situation.StructureJson[0].YardConfigDetailJson[0].AreaJson,
            ];
            this.layoutZone = this.layoutZone.map((item) =>
              Object.assign({}, item, {
                resizable: true,
                elementType: item.YardBlockId ? 'block' : 'area',
                i: item.YardBlockId ? item.YardBlockId : item.YardAreaId,
              })
            );
          }
        },
        view: function (newId, oldId) {
          if (newId && newId!='') {
            if(newId != oldId) this.selectedOptions = [];

            this.getPreferencias();
          }
          else {
            this.selectedOptions = [];
            this.preferences = [];
          } 
        },
      }
  }
</script>
<style scoped>
.position-icon{
    position: absolute;
    top: 9px;
    left: 30px;
    z-index: 1;
  }
/* Bay View */
.bay-view-container {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 100%;
  /* overflow: hidden; */
}
.bay-view {
  position: relative;
  margin: 10px 5px;
  width: 100%;
}

/* Contenido de los Bays */
.bay-container {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 773px;
  position: relative;
}
.bay {
  position: relative;
  margin: 0 0 5px 5px;
  width: 100%;
  padding: 10px 0;
  /* overflow: hidden; */
}
.bay-info {
  /* position: absolute;
  bottom: -18px;
  right: -15px; */
  width: 165px;
  height: 85px;
  margin-left: auto
}
.bay-info ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* Zoom */
.zoom-container {
  width: 100%;
  max-height: 800px;
  /* overflow: auto; */
  min-height: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* scrollbar-width: thin; */
}
.zoom-center {
  display: flex;
  justify-content: center;
}
.sidebar-controls {
  display: flex;
  
  position: absolute;
  bottom: -32px;
  left: -1px;
  /* z-index: 3; */
}
.zoom-number {
  padding:5px;
  background-color: #d6d7db;
  border: 1px solid #636f83;
  color: #000;
}
.zoom-number:hover {
  background-color: #636f83;
  color: #d8dbe0;
}
.grab-bing {
  cursor : -webkit-grab;
  cursor : -moz-grab;
  cursor : -o-grab;
  cursor : grab;
}
.grab-bing:active {
  cursor : -webkit-grabbing;
  cursor : -moz-grabbing;
  cursor : -o-grabbing;
  cursor : grabbing;
}

/* Sidebar */
.yard-body #detailSidebar {
  min-width: 200px !important;
  max-width: 200px !important;
  background-color: #e6e3e3 !important;
  background-image: none;
  border: 1px solid #000;
  transition: all 0.3s;
  z-index: 1;
}
/* .yard-body #content {
  min-height: 834px;
} */
.sidebar-inner-space {
  /* margin-top: 2rem !important */
  margin-top: 0;
}
.yard-body #detailSidebar.active {
  width: 0;
	height: 0;
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 0; /* sólo en caso de elementos inline-block */
  /* overflow: hidden; */
  margin-left: -200px;
}
.btn-sidebar-detail {
  position: absolute;
  bottom: -32px;
  left: -1px;
  z-index: 3;
}

/* Navbar */
.menu-view-cargo.bg-secondary {
  background-color: #f5f7fa !important;
  border: 1px solid #636f83;
  /* border-top: none; */
  box-shadow: 0 6px 6px -6px #000;
}
.menu-view-cargo.no-margin {
  box-shadow: none;
  /* margin-bottom: 0; */
}
.nav-internal-item {
  color: #8994a8 !important;
}
.nav-internal-item:hover {
  color: #556279 !important;
}
.nav-internal-dropdown {
  background-color: #f5f7fa !important;
}
.no-margin-select {
  margin-bottom: 0;
}
.selects-container {
  display:flex; 
  justify-content: center;
  align-items: center;
}

/* Pagination */
.btn-pagination {
  position: absolute;
  bottom: -32px;
  right: 0;
  z-index: 3;

  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
}

/* Filtros multiples */
.filter-collapse {
  width: 100%;
  cursor: pointer;
  color: #4e4c4d;
  background-color: #f8f6f7;
  border: 1px solid #abaaa8;
  border-left: none;
  border-right: none;

  display: flex;
  justify-content: center;
  align-items: center;
}
.arrow-icon {
  transition: transform 0.15s ease-in;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.arrow-icon.rotate {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.selects-container-general {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
</style>